import React from "react"
import { Link } from "gatsby"

import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import Button from "../components/Button"
import Container from "../components/Container"
import SectionTitle from "../components/SectionTitle"
import SectionText from "../components/SectionText"
import PageNotFoundImage2 from "../images/svg/page-not-found-2.svg"

const PageNotFound1 = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          bottomWrapperClassName: "!justify-center",
          bottomClassName: "bg-primary-25",
          className: "bg-primary-25",
        }}
      >
        <Section isHero>
          <Container>
            {/* wrap */}
            <div className="flex flex-col laptop:flex-row items-center justify-center gap-16 laptop:gap-[100px]">
              {/* left-part */}
              <div className="">
                <div className="flex flex-col">
                  <SectionTitle isHero={true} className="!pb-2 !font-bold">
                    404
                  </SectionTitle>
                  <h6 className="pb-4 font-medium laptop:pb-6 text-neutral-900 text-display-sm">
                    Page not found!
                  </h6>
                  <SectionText isHero={true} className="max-w-[304px] mx-auto">
                    Sorry we’ve unable to find the page you’re looking for.
                  </SectionText>
                  <Link to="/home-2">
                    <Button className="" size={"2xl"}>
                      Go to home
                    </Button>
                  </Link>
                </div>
              </div>
              {/* right-part */}
              <div className="">
                <PageNotFoundImage2 className="w-full h-auto tablet:w-auto"></PageNotFoundImage2>
              </div>
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default PageNotFound1
